import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["category", "subcategory"]
  
  connect() {
    console.log("Category select controller connected")
    this.updateSubcategories()
  }
  
  updateSubcategories() {
    const category = this.categoryTarget.value
    const subcategories = this.subcategoriesMap[category] || []
    const currentSubcategory = this.subcategoryTarget.value
    
    // Clear existing options
    this.subcategoryTarget.innerHTML = ''
    
    // Add new options
    subcategories.forEach(subcategory => {
      const option = new Option(subcategory, subcategory, false, subcategory === currentSubcategory)
      this.subcategoryTarget.add(option)
    })
  }
  
  get subcategoriesMap() {
    return JSON.parse(this.element.dataset.subcategories)
  }
} 